import React, { useState, useEffect, useCallback } from 'react';
import './App.css';

const Frontend = () => {
  const [domain, setDomain] = useState('');
  const [tags, setTags] = useState('');
  const [amount, setAmount] = useState(1);
  const [frequency, setFrequency] = useState(1);
  const [isMonitoring, setIsMonitoring] = useState(false);
  const [enableMonitor, setEnableMonitor] = useState(false);
  const [fetchedTags, setFetchedTags] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [log, setLog] = useState([]); // Define log state

  const logMessage = useCallback((message) => {
    setLog((prevLog) => [...prevLog, `[${new Date().toLocaleTimeString('en-US', { hour12: false })}] ${message}`]);

    // Scroll to the bottom of the log window after updating logs
    setTimeout(() => {
      const logWindow = document.querySelector('.log-window');
      if (logWindow) {
        logWindow.scrollTop = logWindow.scrollHeight;
      }
    }, 100);
  }, []);

  const handleDomainChange = (e) => {
    setDomain(e.target.value);
  };

  const handleTagsChange = (e) => {
    setTags(e.target.value);
    const inputValue = e.target.value.split(',').pop().trim(); // Get the last typed tag
    displayTagSuggestions(inputValue); // Show suggestions based on the current input
  };

  const handleAmountChange = (e) => {
    setAmount(e.target.value);
  };

  const handleFrequencyChange = (e) => {
    setFrequency(e.target.value);
  };

  const sanitizeDomain = (domain) => {
    return domain.replace(/(^\w+:|^)\/\//, '');
  };

  const fetchTagsFromUrl = useCallback(async (sanitizedDomain) => {
    const productsJsonUrl = `https://${sanitizedDomain}/products.json`;
    try {
      const response = await fetch(productsJsonUrl);
      const data = await response.json();
      if (data && data.products) {
        const tagCounts = {};
        data.products.forEach((product) => {
          if (product.variants.some(variant => variant.available)) {
            product.tags.forEach((tag) => {
              const lowerTag = tag.toLowerCase().trim();
              if (!tagCounts[lowerTag]) {
                tagCounts[lowerTag] = 0;
              }
              tagCounts[lowerTag] += product.variants.filter(variant => variant.available).length;
            });
          }
        });
        const uniqueTags = Object.keys(tagCounts);
        setFetchedTags(uniqueTags.map(tag => ({ tag, count: tagCounts[tag] })));
        logMessage(`✅ Fetched available tags: ${uniqueTags.map(tag => `${tag} (${tagCounts[tag]})`).join(', ')}`);
      } else {
        logMessage(`🚫 No valid products found at ${productsJsonUrl}`);
      }
    } catch (error) {
      logMessage(`🚫 Error: ${error.message}`);
    }
  }, [logMessage]);

useEffect(() => {
  if (domain) {
    fetchTagsFromUrl(sanitizeDomain(domain));
  }
}, [domain, fetchTagsFromUrl]);

const displayTagSuggestions = (inputValue) => {
  if (fetchedTags.length > 0 && inputValue.trim() !== '') {
    const filteredTags = fetchedTags.filter(({ tag }) => tag.includes(inputValue.toLowerCase()));
    setSuggestions(filteredTags);
  } else {
    setSuggestions([]); // Clear suggestions if no input
  }
};

const handleTagClick = (tag) => {
  let currentTags = tags.split(',').map(tag => tag.trim());
  currentTags[currentTags.length - 1] = tag; // Replace last typed tag with clicked suggestion
  setTags(currentTags.join(', ')); // Update tags input
  setSuggestions([]); // Clear suggestions after selecting a tag
};

  const startMonitoring = async () => {
    if (!enableMonitor) return; // Prevent starting if monitor is not enabled

    const sanitizedDomain = sanitizeDomain(domain);
    const data = {
      domain: sanitizedDomain,
      tags: tags.split(',').map((tag) => tag.trim()),
      amount: parseInt(amount),
      frequency: parseInt(frequency)
    };
    try {
      const response = await fetch('https://atc.nimsec.se:3001/monitor', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
      });
      const result = await response.json();
      if (result.success) {
        setIsMonitoring(true);
        logMessage(`✅ Monitoring started successfully for ${domain}`);
      } else {
        logMessage(`🚫 Error: ${result.message}`);
      }
    } catch (error) {
      logMessage(`🚫 Error: ${error.message}`);
    }
  };

  const stopMonitoring = async () => {
    const sanitizedDomain = sanitizeDomain(domain);
    try {
      const response = await fetch('https://atc.nimsec.se:3001/stop-monitoring', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ domain: sanitizedDomain })
      });
      const result = await response.json();
      if (result.success) {
        logMessage(`⏹ Monitoring stopped for ${domain}`);
        setIsMonitoring(false);
      } else {
        logMessage(`🚫 Error: ${result.message}`);
      }
    } catch (error) {
      logMessage(`🚫 Error: ${error.message}`);
    }
  };

  const runOnce = async () => {
  if (enableMonitor) return; // Prevent runOnce from running if monitoring is enabled

  const sanitizedDomain = sanitizeDomain(domain);
  const data = {
    domain: sanitizedDomain,
    tags: tags.split(',').map((tag) => tag.trim()),
    amount: parseInt(amount),
  };
  try {
    const response = await fetch('https://atc.nimsec.se:3001/run-once', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    });
    const result = await response.json();
    if (result.success) {
      logMessage('✅ Run once completed successfully.');

      // Detect if the user is on a mobile device
      const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

      if (isMobile) {
        // Redirect to the checkout URL on mobile devices
        window.location.href = result.checkoutUrl;
      } else {
        // Open in a new tab on desktop devices
        const newTab = window.open(result.checkoutUrl, '_blank');
        if (!newTab || newTab.closed || typeof newTab.closed == 'undefined') {
          // Fallback mechanism if window.open is blocked
          const fallbackLink = document.createElement('a');
          fallbackLink.href = result.checkoutUrl;
          fallbackLink.target = '_blank';
          fallbackLink.rel = 'noopener noreferrer';
          fallbackLink.click();
        }
      }
    } else {
      logMessage('🚫 Error: ' + result.message);
    }
  } catch (error) {
    logMessage('🚫 Error: ' + error.message);
  }
};

  return (
    <div className="container">
      <h1>Shopify ATC/Monitor</h1>
  
      <label>Domain or Specific URL</label>
      <input type="text" value={domain} onChange={handleDomainChange} placeholder="e.g., https://poshpeanut.com/" />
  
      <label>Tags (comma-separated)</label>
      <input type="text" value={tags} onChange={handleTagsChange} placeholder="e.g., one piece, blanket" />
      {/* Render suggestions below the tags input */}
      {suggestions.length > 0 && (
        <ul className="suggestions-box">
          {suggestions.map(({ tag, count }, index) => (
            <li key={index} onClick={() => handleTagClick(tag)} className="suggestion-item">
              {tag} ({count} available)
            </li>
          ))}
        </ul>
      )}
  
      <label>Amount</label>
      <input type="number" value={amount} onChange={handleAmountChange} min="1" />
  
      <div className="checkbox-container">
        <label htmlFor="monitor-toggle">Enable Monitor</label>
        <input
          type="checkbox"
          id="monitor-toggle"
          checked={enableMonitor}
          onChange={() => setEnableMonitor(!enableMonitor)}
        />
      </div>
  
      <label>Frequency</label>
      <select
        value={frequency}
        onChange={handleFrequencyChange}
        disabled={!enableMonitor} /* Conditionally disable the dropdown */
      >
        <option value="1000">1 second</option>
        <option value="5000">5 seconds</option>
        <option value="15000">15 seconds</option>
        <option value="30000">30 seconds</option>
        <option value="60000">60 seconds</option>
      </select>
  
      <button
        onClick={enableMonitor ? (isMonitoring ? stopMonitoring : startMonitoring) : runOnce}
        disabled={!enableMonitor && isMonitoring} // Disable the button if monitoring is in progress without checkbox
      >
        {enableMonitor
          ? (isMonitoring ? 'Stop Monitoring' : 'Start Monitoring')
          : 'Run Once'}
      </button>
  
      <div className="log-window">
        {log.map((message, index) => (
          <p key={index}>{message}</p>
        ))}
      </div>
    </div>
  );
}

export default Frontend;
